import React from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Hero } from "../components/Partials/Hero"
import CaseStudyHomepageItem from "../components/Homepage/CaseStudyHomepageItem"
import { LargeText } from "../components/Partials/Typography"
import VerticalSpacer from "../components/Partials/VerticalSpacer"
import ExploreMore from "../components/exploreMore/ExploreMore"
import useToggleFooterColor from "../hooks/useToggleFooterColor"
import AppContainer from "../styles/AppContainer"

const IndexPage = ({ data }) => {
    const { heading } = get(data, "allHomeJson.edges[0].node", {})
    const { copy: headingCopy } = heading

    console.log("data", data)

    const contentBlocks = get(data, "allHomeJson.edges[0].node.contentBlocks")

    const exploreMore = get(data, "allHomeJson.edges[0].node.exploreMore", {})

    console.log("contentBlocks: ", contentBlocks)

    console.log("exploreMore: ", exploreMore)

    const doesCTAExist = !!headingCopy.slug

    useToggleFooterColor({ useDefault: true })

    return (
        <>
            <AppContainer>
                <SEO
                    title="Descriptive | A digital product design and development company."
                    description="We design and build digital products that help companies achieve their business goals through innovation and creativity."
                />
                <Hero
                    heading={headingCopy}
                    isHome
                    hasLine
                    ctaExists={doesCTAExist}
                />
                <VerticalSpacer size="xxl" />
                {contentBlocks.length &&
                    contentBlocks.map((contentBlock, i) => {
                        return (
                            <>
                                <section key={i}>
                                    {contentBlock.type === "Case Study" && (
                                        <>
                                            <CaseStudyHomepageItem
                                                caseStudy={contentBlock}
                                            />
                                            <VerticalSpacer size="xxl" />
                                        </>
                                    )}
                                    {(contentBlock.type === "Our Approach" ||
                                        contentBlock.type === "Contact Us") && (
                                        <>
                                            <LargeText
                                                copy={contentBlock.text}
                                                hasDarkBackground
                                            />
                                            <VerticalSpacer size="xxl" />
                                        </>
                                    )}
                                </section>
                            </>
                        )
                    })}
                <ExploreMore
                    heading={exploreMore.heading}
                    exploreMoreLinks={exploreMore.exploreMoreLinks}
                />
            </AppContainer>
        </>
    )
}
export default IndexPage

export const query = graphql`
    query {
        allHomeJson {
            edges {
                node {
                    heading {
                        copy {
                            header
                            cta
                            slug
                        }
                    }
                    contentBlocks {
                        type
                        client
                        homepagePreview
                        video {
                            src
                            alt
                            poster
                        }
                        slug
                        cta
                        text
                    }
                    exploreMore {
                        heading
                        exploreMoreLinks {
                            id
                            slug
                            cta
                        }
                    }
                }
            }
        }
    }
`
