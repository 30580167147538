import React from "react"
import styled from "@emotion/styled"
import RightContainer from "../Partials/RightContainer"
import { HeaderTwoLarge, TextButton } from "../Partials/Typography"
import VerticalSpacer from "../Partials/VerticalSpacer"
import { spacer } from "../../styles/sizes"
import { colors } from "../../styles/colors"

const ExploreMore = ({ exploreMoreLinks = [], heading }) => {
    return (
        <>
            <ExploreMoreLinksContainer>
                <RightContainer>
                    <FlexColumn>
                        <HeaderTwoLarge copy={heading} />
                        <VerticalSpacer size="s" />
                        <List>
                            {exploreMoreLinks.map(({ id, slug, cta }) => (
                                <>
                                    <CTABorder>
                                        <TextButton
                                            key={id}
                                            to={slug}
                                            copy={cta}
                                            isExploreMore
                                        />
                                    </CTABorder>
                                </>
                            ))}
                        </List>
                    </FlexColumn>
                </RightContainer>
            </ExploreMoreLinksContainer>
        </>
    )
}

export default ExploreMore

const ExploreMoreLinksContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const List = styled.div`
    display: flex;
    flex-direction: column;
`

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
`

const CTABorder = styled.div`
    border-bottom: 1px solid ${colors.silver};
    padding: ${spacer.l} 0;
`
