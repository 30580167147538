import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { H2Large, P, Tag } from "../Partials/Typography"
import ReactHtmlParser from "react-html-parser"
import VerticalSpacer from "../Partials/VerticalSpacer"
import { TextButton } from "../Partials/Typography"
import { colors } from "../../styles/colors"
import { deviceMinW } from "../../styles/mediaQueries"
import { spacer } from "../../styles/sizes"
import { fontSize } from "../../styles/fonts/fontStyle"

const CaseStudyItem = ({ caseStudy, isHome }) => {
    const videoEl = useRef(null)

    const attemptPlay = () => {
        videoEl &&
            videoEl.current &&
            videoEl.current.play().catch(error => {
                console.error("Error attempting to play", error)
            })
    }

    useEffect(() => {
        attemptPlay()
    }, [])

    return (
        <>
            <CaseStudyItemContainer isHome={isHome}>
                {isHome === true ? (
                    <>
                        <TagContainer>
                            <Tag blackTag>Case Study</Tag>
                            <Tag purpleTag>
                                {ReactHtmlParser(caseStudy.client)}
                            </Tag>
                        </TagContainer>
                        <VerticalSpacer size="m" />
                    </>
                ) : (
                    <>
                        <H2Large isTan>
                            {ReactHtmlParser(caseStudy.client)}
                        </H2Large>
                        <VerticalSpacer size="s" />
                    </>
                )}
                {isHome === true ? (
                    <P
                        isHome
                        css={css`
                            font-weight: 500;
                            font-size: ${fontSize.doublePica};
                            line-height: 1.3;

                            @media ${deviceMinW.laptop} {
                                font-size: ${fontSize.trafalgar};
                            }
                        `}
                    >
                        {ReactHtmlParser(caseStudy.homepagePreview)}
                    </P>
                ) : (
                    <P isTan>{ReactHtmlParser(caseStudy.description)}</P>
                )}
                <VerticalSpacer size="l" />
                <Link
                    to={caseStudy.slug}
                    aria-label="Click here to read the case study."
                >
                    <CaseStudyVideo
                        autoload={true}
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        playsInline={true}
                        ref={videoEl}
                        alt={caseStudy.video.alt}
                        src={caseStudy.video.src}
                        poster={caseStudy.video.poster}
                    />
                </Link>
                <VerticalSpacer size="l" />
                {isHome === true ? (
                    <TextButton
                        hasLine
                        to={caseStudy.slug}
                        copy={caseStudy.cta}
                        aria-label="Click here to read the case study."
                    />
                ) : (
                    <TextButton
                        isTan
                        hasLine
                        to={caseStudy.slug}
                        copy={caseStudy.cta}
                        aria-label="Click here to read the case study."
                    />
                )}
            </CaseStudyItemContainer>
        </>
    )
}
export default CaseStudyItem

const CaseStudyItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid ${colors.pampas};
    padding-bottom: 6rem;
    padding-top: 6rem;

    &:first-of-type {
        padding-top: 0;
    }
    &:last-of-type {
         border-bottom: none;
    }

    ${props =>
        props.isHome &&
        css`
            padding-bottom: 0;
            border-bottom: none;
        `}

        @media ${deviceMinW.tablet} {
            padding-top: ${spacer.xl};
        }
`
const TagContainer = styled.div`
    display: flex;
    flex-direction: row;
`
const CaseStudyVideo = styled.video`
    width: 100%;
`
