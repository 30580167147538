import React from "react"
import styled from "@emotion/styled"
import CaseStudyItem from "../caseStudies/CaseStudyItem"
import RightContainer from "../Partials/RightContainer"

const CaseStudyHomepageItem = ({ caseStudy }) => {
    return (
        <ListContainer>
            <RightContainer>
                <List>
                    <CaseStudyItem caseStudy={caseStudy} isHome={true} />
                </List>
            </RightContainer>
        </ListContainer>
    )
}
export default CaseStudyHomepageItem

const ListContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const List = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
`
